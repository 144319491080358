.Logo .topa{
    position: relative;
    width: 100%;
    height: 300px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-content:space-between;
}
.Logo .topa .top{
    height: 150px;
    background-color: #37A089;
}
.Logo .topa .img{
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.Logo .topa .imgs{
    width: 80%;
    margin: auto;
    margin-top: 100px;
}
.Logo .bottom{
    text-align: center;
}
.Logo .bottom span{
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    padding: 8px 3px;
    border-top: 1px solid #ACACAC;
    border-bottom: 1px solid #ACACAC;
    color: #ACACAC;
}