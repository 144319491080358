.topFrom .mask {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    display: flex;
    justify-content: center;

}

.topFrom .mask .text {
    opacity: 1;
    margin-top: 5vh;
}

.topFrom .header {
    text-align: center;
    color: #37A089;
    font-size: 30px;
    font-weight: 600;
}

.topFrom .form_intro {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
    color: #E46767;
}

.topFrom input {
    font-size: 16px;
    width: 70%;
    padding: 10px 10px;
    margin: 5px 0;
    border-radius: 10px;
    outline-style: none;
    border: 3px solid #24977E;
}

.topFrom .input_top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;

}

.topFrom .input_top input {
    width: 50%;
}

.topFrom .button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.topFrom .button span {
    background-color: #37A089;
    color: #FFF;
    font-size: 18px;
    padding: 10px 30px;
    font-weight: 600;
    border-radius: 10px;
}

.topFrom .bottom div {
    text-align: center;
    font-size: 12px;
    margin: 10px;
}

.topFrom .bottom span {
    color: #E46767;
}

.topCard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topCard .cradma {
    width: 150px;
    height: 150px;
    margin-top: 50px;
    animation-name: header;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.topCard .cardpng {
    width: 90%;
    margin-top: 50px;
    animation-name: form_intro;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.topCard .header {
    text-align: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 50px;
    color: #75C860;
    animation-name: header;
    animation-duration: 1s;
    animation-iteration-count: 1;

}

@keyframes header {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes form_intro {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0%);
    }
}

.topCard .form_intro {
    animation-name: form_intro;
    animation-duration: 1s;
    animation-iteration-count: 1;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
    color: #E3204A;
}

.topCard .number {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
    animation-name: header;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

.topCard .intro {
    font-size: 12px;
    margin-top: 50px;
    color: #A4A4A4;
    animation-name: form_intro;
    animation-duration: 1s;
    animation-iteration-count: 1;
}